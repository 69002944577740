<template>
  <div class="controlled-entries-tab">
    <h5 class="card-title">Controlled Entries</h5>
    <p class="text-muted mb-1">The controlled entries in the building.</p>
    <ul class="text-muted mb-4">
      <li>
        The following table shows all controlled entries which are in any of the zones in the
        building.
      </li>
      <li>
        To add a controlled entry to any of the zones in the building, go to the zone detail or the
        controlled entry detail.
      </li>
    </ul>
    <phillip-table
      table="controlled-entry"
      hide-new-button
      hide-filters
      hide-delete-button
      edit-button-label="Detail"
      empty-text="No controlled entries in the building."
      :filters="{ 'buildingId.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'building-detail-controlled-entries-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
