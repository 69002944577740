import { render, staticRenderFns } from "./UserSipAccountTableBase.vue?vue&type=template&id=d4fc0b6e&"
import script from "./UserSipAccountTableBase.vue?vue&type=script&lang=js&"
export * from "./UserSipAccountTableBase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports