<template>
  <div>
    <phillip-table
      table="user-group"
      hide-new-button
      hide-edit-button
      delete-button-label="Remove from blacklist"
      hide-filters
      empty-text="The blacklist is empty."
      :filters="{ [tableFilterName]: storage.pk(), 'owner.null': true }"
      :on-delete="removeFromBlacklist"
    />
    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 450px"
        select="user-group"
        placeholder="Add group to blacklist"
        v-model="userGroup"
        :filters="{ 'owner.null': true }"
      />
      <button
        @click="addToBlacklist"
        :disabled="userGroup === null"
        class="btn btn-sm btn-primary text-white ml-3"
      >
        Blacklist group
      </button>
    </div>
  </div>
</template>

<script>
/**
 * A general blacklist form which can be used for a device of any type. The
 * requirements for the device are:
 *  - method `addWhitelist` must be implemented
 *  - method `deleteWhitelist` must be implemented
 *
 * Also the name of the filter, which returns all blacklisted groups
 * blacklisted by the device, must be specified
 */

import DataStorage from '../../model/abstract/data-storage';
import userGroupFactory from '../../model/user-group/user-group-factory';

export default {
  name: 'blacklist-tab',
  props: {
    storage: {
      type: DataStorage,
      required: true,
    },
    tableFilterName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userGroup: null,
    };
  },
  methods: {
    addToBlacklist() {
      if (this.userGroup !== null) {
        Promise.all([
          this.storage.addBlacklist(this.userGroup),
          this.storage.deleteWhitelist(this.userGroup),
        ])
          .then(() => {
            this.userGroup = null;
            userGroupFactory.signalChange();
          })
          .catch((e) => this.$modal.alert(e));
      }
    },
    removeFromBlacklist(userGroupId) {
      this.$modal
        .confirm(`Remove group ${userGroupId} from blacklist?`)
        .then(() => {
          this.storage
            .deleteBlacklist(userGroupId)
            .then(() => userGroupFactory.signalChange())
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // Do nothing
    },
  },
};
</script>
