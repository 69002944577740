<template>
  <card
    class="short page-new-sip-door"
    title="New SIP Door"
    description="The general info about the SIP door. More settings will be availabe after the SIP door is created."
  >
    <phillip-form form="sip-door" />
  </card>
</template>

<script>
import sipDoorFactory from '../../model/sip-door/sip-door-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-sip-door',
  methods: {
    ...mapMutations({
      setSipDoor: 'sipDoor/form/setStorage',
    }),
  },
  created() {
    this.setSipDoor(sipDoorFactory.blank());
  },
  beforeDestroy() {
    this.setSipDoor(null);
  },
};
</script>
