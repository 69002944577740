<template>
  <div class="user-access-tab">
    <h4 class="card-title">Users</h4>
    <p class="text-muted mb-1">The list of users who can access the SIP Door.</p>
    <ul class="text-muted mb-4">
      <li>
        All users who are part of at least one group which can access the SIP door also gain the SIP
        door access.
      </li>
    </ul>
    <phillip-table
      table="user"
      hide-filters
      hide-new-button
      hide-delete-button
      edit-button-label="Detail"
      empty-text="Noone can access the SIP door."
      :filters="{ 'sipDoorAccess.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'sip-door-detail-user-access-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
