<template>
  <component :is="`${display}-lazy-display`" v-bind="$attrs" />
</template>

<script>
import AccessTokenLazyDisplay from './AccessTokenLazyDisplay';
import AreaLazyDisplay from './AreaLazyDisplay';
import BillingAddressLazyDisplay from './BillingAddressLazyDisplay';
import BuildingLazyDisplay from './BuildingLazyDisplay';
import CameraLazyDisplay from './CameraLazyDisplay';
import ChastiaDeviceLazyDisplay from './ChastiaDeviceLazyDisplay';
import ChastiaDeviceMeterTypeLazyDisplay from './ChastiaDeviceMeterTypeLazyDisplay';
import ChastiaFunctionLazyDisplay from './ChastiaFunctionLazyDisplay';
import ChastiaSensorLazyDisplay from './ChastiaSensorLazyDisplay';
import ChastiaSensorMeasuringTypeLazyDisplay from './ChastiaSensorMeasuringTypeLazyDisplay';
import ControlledEntryLazyDisplay from './ControlledEntryLazyDisplay';
import DoorBellLazyDisplay from './DoorBellLazyDisplay';
import EmailMessageLazyDisplay from './EmailMessageLazyDisplay';
import FirebaseMessageLazyDisplay from './FirebaseMessageLazyDisplay';
import IosVoipMessageLazyDisplay from './IosVoipMessageLazyDisplay';
import LicensePlateLazyDisplay from './LicensePlateLazyDisplay';
import SessionLazyDisplay from './SessionLazyDisplay';
import SipDoorLazyDisplay from './SipDoorLazyDisplay';
import SipDoorActionLazyDisplay from './SipDoorActionLazyDisplay';
import UserLazyDisplay from './UserLazyDisplay';
import UserGroupLazyDisplay from './UserGroupLazyDisplay';
import UserSipAccountLazyDisplay from './UserSipAccountLazyDisplay';

export default {
  name: 'phillip-lazy-display',
  components: {
    AccessTokenLazyDisplay,
    AreaLazyDisplay,
    BillingAddressLazyDisplay,
    BuildingLazyDisplay,
    CameraLazyDisplay,
    ChastiaDeviceLazyDisplay,
    ChastiaDeviceMeterTypeLazyDisplay,
    ChastiaFunctionLazyDisplay,
    ChastiaSensorLazyDisplay,
    ChastiaSensorMeasuringTypeLazyDisplay,
    ControlledEntryLazyDisplay,
    DoorBellLazyDisplay,
    EmailMessageLazyDisplay,
    FirebaseMessageLazyDisplay,
    IosVoipMessageLazyDisplay,
    LicensePlateLazyDisplay,
    SessionLazyDisplay,
    SipDoorLazyDisplay,
    SipDoorActionLazyDisplay,
    UserLazyDisplay,
    UserGroupLazyDisplay,
    UserSipAccountLazyDisplay,
  },
  props: {
    display: {
      type: String,
      required: true,
    },
  },
};
</script>
