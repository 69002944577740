<template>
  <div class="measurements-tab">
    <h4 class="card-title">Measurements</h4>
    <p class="text-muted mb-1">The latest measurements taken by the sensor.</p>

    <div class="mt-4" v-if="chastiaSensor">
      <table
        v-if="
          chastiaSensor.chastiaLastValue !== null && chastiaSensor.chastiaLastValue !== undefined
        "
        class="table"
      >
        <tbody>
          <tr>
            <th style="width: 100px">Time</th>
            <td>{{ diff }}</td>
          </tr>
          <tr>
            <th style="width: 100px">Value</th>
            <td>{{ chastiaSensor.chastiaLastValue }} {{ chastiaSensor.chastiaUnit }}</td>
          </tr>
        </tbody>
      </table>
      <p class="alert alert-danger" v-else>There is no measured value!</p>
    </div>

    <p v-else>Loading ...</p>
  </div>
</template>

<script>
import chastiaSensorFactory from '../../../../model/chastia-sensor/chastia-sensor-factory';

export default {
  name: 'chastia-sensor-detail-measurements-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    diff() {
      let diff = (Date.now() - new Date(this.chastiaSensor.chastiaLastDateUtc).getTime()) / 60000;

      if (diff < 1) {
        return 'Less than a minute ago';
      }

      diff = Math.round(diff);

      return `About ${diff} minute${diff > 1 ? 's' : ''} ago`;
    },
  },
  data() {
    return {
      chastiaSensor: null,
    };
  },
  async created() {
    this.chastiaSensor = await chastiaSensorFactory.pk(this.pk);
  },
};
</script>
