<template>
  <card
    class="page-session"
    title="All active sessions"
    description="The list of all active sessions of all users."
  >
    <phillip-table hide-new-button hide-edit-button disable-detail table="session" />
  </card>
</template>

<script>
export default {
  name: 'page-controlled-entries',
};
</script>
