import accessToken from './access-token';
import area from './area';
import billingAddress from './billing-address';
import building from './building';
import camera from './camera';
import chastiaDevice from './chastia-device';
import chastiaDeviceMeterType from './chastia-device-meter-type';
import chastiaFunction from './chastia-function';
import chastiaSensor from './chastia-sensor';
import chastiaSensorMeasuringType from './chastia-sensor-measuring-type';
import controlledEntry from './controlled-entry';
import doorBell from './door-bell';
import emailMessage from './email-message';
import firebaseMessage from './firebase-message';
import iosVoipMessage from './ios-voip-message';
import licensePlate from './license-plate';
import session from './session';
import sipDoor from './sip-door';
import sipDoorAction from './sip-door-action';
import user from './user';
import userGroup from './user-group';
import userSipAccount from './user-sip-account';

export default {
  namespaced: false,
  modules: {
    accessToken,
    area,
    billingAddress,
    building,
    camera,
    chastiaDevice,
    chastiaDeviceMeterType,
    chastiaFunction,
    chastiaSensor,
    chastiaSensorMeasuringType,
    controlledEntry,
    doorBell,
    emailMessage,
    firebaseMessage,
    iosVoipMessage,
    licensePlate,
    session,
    sipDoor,
    sipDoorAction,
    user,
    userGroup,
    userSipAccount,
  },
};
