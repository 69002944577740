<template>
  <div class="blacklist-tab">
    <h5 class="card-title">Blacklist</h5>
    <p class="text-muted mb-4">The list of groups which are denied access to the Chastia device.</p>
    <blacklist-form
      :storage="chastiaDeviceFactory.pkMock(pk)"
      table-filter-name="chastiaDeviceBlacklist.eq"
    />
  </div>
</template>

<script>
import chastiaDeviceFactory from '../../../model/chastia-device/chastia-device-factory';
import BlacklistForm from '../../../components/forms/BlacklistForm.vue';

export default {
  name: 'chastia-device-detail-blacklist-tab',
  components: {
    BlacklistForm,
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      chastiaDeviceFactory,
    };
  },
};
</script>
