<template>
  <form :id="formId" @submit.prevent="onSubmit" class="model-form">
    <template v-for="(cfg, key) in config">
      <template
        v-if="
          !cfg.ignore && (!cfg.onlyIfNew || storage.isNew) && (!cfg.onlyIfEdit || !storage.isNew)
        "
      >
        <div :key="key" class="form-group row">
          <!-- Label -->
          <label class="col-sm-3 col-form-label">
            {{ cfg.label }}
            <abbr v-if="cfg.required" class="text-danger" title="This field is required">*</abbr>
          </label>

          <!-- Input -->
          <div class="col-sm-9">
            <component
              :is="`${cfg.type}`"
              v-bind="{ ...cfg, type: undefined }"
              v-model="storage[key]"
            />
          </div>
        </div>
      </template>
    </template>

    <code v-if="session.debug">
      <pre>{{ storage }}</pre>
    </code>

    <!-- Submit -->
    <button
      v-if="!hideSubmitButton"
      :disabled="loading"
      type="submit"
      class="btn btn-primary btn-sm submit text-white"
    >
      Save
    </button>
  </form>
</template>

<script>
import { nanoid } from 'nanoid/non-secure';
import DataStorage from '../../model/abstract/data-storage';
import DateInput from '../inputs/DateInput';
import EmailInput from '../inputs/EmailInput';
import NumberInput from '../inputs/NumberInput';
import PasswordInput from '../inputs/PasswordInput';
import SelectInput from '../inputs/SelectInput';
import TelInput from '../inputs/TelInput';
import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import UrlInput from '../inputs/UrlInput';
import session from '../../api/session';

export default {
  name: 'model-form',
  components: {
    DateInput,
    EmailInput,
    NumberInput,
    PasswordInput,
    SelectInput,
    TelInput,
    TextareaInput,
    TextInput,
    UrlInput,
  },
  props: {
    formId: {
      type: String,
      default: () => nanoid(),
    },
    storage: {
      type: DataStorage,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    hideSubmitButton: {
      type: Boolean,
      default: false,
    },
    noRedirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      session,
    };
  },
  methods: {
    async onSubmit() {
      if (this.loading) {
        return; // Prevent multiple submits
      }

      this.loading = true;

      try {
        const wasNew = this.storage.isNew;

        await this.storage.save();

        if (wasNew && !this.noRedirect) {
          const model = this.storage._factory.getModelName();
          const pk = this.storage._factory.getPrimaryKey();

          this.$router.push(`/${model}/${this.storage[pk]}`);
        }

        this.$emit('success');
      } catch (err) {
        console.log(err);
        alert(err);
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    Object.entries(this.config).forEach(([key, cfg]) => {
      const defaultSpecified = Object.prototype.hasOwnProperty.call(cfg, 'default');
      const valueMissing = !Object.prototype.hasOwnProperty.call(this.storage, key);

      if (defaultSpecified && valueMissing) {
        this.storage[key] = cfg.default();
      }
    });
  },
};
</script>
