<template>
  <div class="user-access-tab">
    <h4 class="card-title">Users</h4>
    <p class="text-muted mb-1">The list of users who can access the Chastia device.</p>
    <ul class="text-muted mb-4">
      <li>
        All users who are part of at least one group which can access the Chastia device also gain
        the Chastia device access.
      </li>
    </ul>
    <phillip-table
      table="user"
      hide-filters
      hide-new-button
      hide-delete-button
      edit-button-label="Detail"
      empty-text="Noone can access the Chastia device."
      :filters="{ 'chastiaDeviceAccess.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'chastia-device-detail-user-access-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
