<template>
  <card
    class="short page-new-controlled-entry"
    title="New Controlled Entry"
    description="The general info about the controlled entry. More settings will be availabe after the controlled entry is created."
  >
    <phillip-form form="controlled-entry" />
  </card>
</template>

<script>
import controlledEntryFactory from '../../model/controlled-entry/controlled-entry-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-controlled-entry',
  methods: {
    ...mapMutations({
      setControlledEntry: 'controlledEntry/form/setStorage',
    }),
  },
  created() {
    this.setControlledEntry(controlledEntryFactory.blank());
  },
  beforeDestroy() {
    this.setControlledEntry(null);
  },
};
</script>
