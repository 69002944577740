<template>
  <ios-voip-message-table-base
    v-bind="$attrs"
    :filters="_filters"
    @clear="clearFilters"
    hide-delete-button
    edit-button-label="Detail"
  >
    <template slot="filters"></template>
  </ios-voip-message-table-base>
</template>

<script>
import IosVoipMessageTableBase from './IosVoipMessageTableBase';

export default {
  name: 'ios-voip-message-table',
  components: {
    IosVoipMessageTableBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  methods: {
    clearFilters() {
      // Clear the filters
    },
  },
  data() {
    return {
      // Filters
    };
  },
};
</script>
