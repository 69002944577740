<template>
  <div class="row w-100 mx-0 auth-page">
    <div class="col-md-6 col-xl-4 mx-auto">
      <div class="card mb">
        <div class="row">
          <div class="col-md-12 pl-md-0">
            <div class="auth-form-wrapper px-4 py-5">
              <a href="#" class="noble-ui-logo d-block mb-2">Phillip <span>Admin</span></a>
              <h5 class="text-muted font-weight-normal mb-4">
                Welcome back! Log in to your account.
              </h5>
              <form class="forms-sample" @submit.prevent="onSubmit">
                <div class="form-group mb-3">
                  <label for="email">Email address</label>
                  <input
                    v-model="email"
                    required
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                  />
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input
                    v-model="password"
                    required
                    type="password"
                    class="form-control"
                    id="password"
                    autocomplete="current-password"
                    placeholder="Password"
                  />
                </div>
                <div class="mt-4 d-flex justify-content-start align-items-center">
                  <button class="btn btn-sm btn-primary mr-2 mb-2 mb-md-0 text-white">Login</button>
                  <router-link to="/reset-password" class="text-muted ml-4">
                    Reset password
                  </router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import session from '../api/session';

export default {
  name: 'page-login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    onSubmit() {
      session.login(this.email, this.password).catch((e) => this.$modal.alert(e));
    },
  },
};
</script>
