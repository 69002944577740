<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <router-link to="/" class="sidebar-brand">Phillip <span>Admin</span></router-link>
      <div class="sidebar-toggler not-active">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body ps">
      <ul class="nav">
        <!-- Users -->
        <li class="nav-item nav-category">Users</li>
        <li class="nav-item" :class="activeIf('/user')">
          <router-link class="nav-link" to="/user">
            <icon icon="feather-user" class="link-icon" />
            <span class="link-title">Users</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/user-group')">
          <router-link class="nav-link" to="/user-group">
            <icon icon="feather-users" class="link-icon" />
            <span class="link-title">Groups</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/session')">
          <router-link class="nav-link" to="/session">
            <icon icon="feather-umbrella" class="link-icon"></icon>
            <span class="link-title">Sessions</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/user-sip-account')">
          <router-link class="nav-link" to="/user-sip-account">
            <icon icon="feather-book-open" class="link-icon"></icon>
            <span class="link-title">SIP Accounts</span>
          </router-link>
        </li>

        <!-- Buildings -->
        <li class="nav-item nav-category">Buildings</li>
        <li class="nav-item" :class="activeIf('/building')">
          <router-link class="nav-link" to="/building">
            <icon icon="feather-home" class="link-icon" />
            <span class="link-title">Buildings</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/area')">
          <router-link class="nav-link" to="/area">
            <icon icon="feather-map-pin" class="link-icon" />
            <span class="link-title">Zones</span>
          </router-link>
        </li>

        <!-- Devices -->
        <li class="nav-item nav-category">Devices</li>
        <li class="nav-item" :class="activeIf('/camera')">
          <router-link class="nav-link" to="/camera">
            <icon icon="feather-camera" class="link-icon"></icon>
            <span class="link-title">Cameras</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/sip-door')">
          <router-link class="nav-link" to="/sip-door">
            <icon icon="feather-phone-forwarded" class="link-icon"></icon>
            <span class="link-title">SIP Doors</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/controlled-entry')">
          <router-link class="nav-link" to="/controlled-entry">
            <icon icon="feather-compass" class="link-icon"></icon>
            <span class="link-title">Controlled Entries</span>
          </router-link>
        </li>

        <!-- Chastia -->
        <li class="nav-item" :class="activeIf('/chastia-device')">
          <router-link class="nav-link" to="/chastia-device">
            <icon icon="feather-tool" class="link-icon"></icon>
            <span class="link-title">Chastia Devices</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/chastia-sensor')">
          <router-link class="nav-link" to="/chastia-sensor">
            <icon icon="feather-battery-charging" class="link-icon"></icon>
            <span class="link-title">Chastia Sensors</span>
          </router-link>
        </li>

        <!-- Other -->
        <li class="nav-item nav-category">Other</li>
        <li class="nav-item" :class="activeIf('/license-plate')">
          <router-link class="nav-link" to="/license-plate">
            <icon icon="feather-truck" class="link-icon"></icon>
            <span class="link-title">License Plates</span>
          </router-link>
        </li>

        <!-- Messages -->
        <li class="nav-item nav-category">Messages</li>
        <li class="nav-item" :class="activeIf('/firebase-message')">
          <router-link class="nav-link" to="/firebase-message">
            <icon icon="feather-mail" class="link-icon"></icon>
            <span class="link-title">Firebase Messages</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/ios-voip-message')">
          <router-link class="nav-link" to="/ios-voip-message">
            <icon icon="feather-mail" class="link-icon"></icon>
            <span class="link-title">iOS VOIP Messages</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/email-message')">
          <router-link class="nav-link" to="/email-message">
            <icon icon="feather-mail" class="link-icon"></icon>
            <span class="link-title">Email Messages</span>
          </router-link>
        </li>

        <template v-if="session.debug">
          <li class="nav-item nav-category">Debug</li>

          <li class="nav-item" :class="activeIf('/api-playground')">
            <router-link class="nav-link" to="/api-playground">
              <icon icon="feather-send" class="link-icon"></icon>
              <span class="link-title">API Playground</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </nav>
</template>

<script>
import session from '../../api/session';

export default {
  name: 'dashboard-layout-sidebar',
  data() {
    return {
      session,
    };
  },
  methods: {
    activeIf(path) {
      return new RegExp(`${path}(/|$)`).test(this.$route.fullPath) ? 'active' : '';
    },
  },
};
</script>

<style lang="scss">
.dashboard-layout__sidebar {
  position: fixed;
  top: 0;
  left: 0;

  width: 200px;
  height: 100vh;

  background-color: #0c1427;
}
</style>
