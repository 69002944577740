import Card from './components/Card.vue';
import Icon from './components/icons/Icon.vue';
import Tabs from './components/tabs/Tabs.vue';
import TabPane from './components/tabs/TabPane.vue';
import NotImplementedBanner from './components/NotImplementedBanner.vue';

import PhillipForm from './components/forms/PhillipForm.vue';
import PhillipTable from './components/data-table/PhillipTable.vue';
import PhillipSelect from './components/data-select/PhillipSelect.vue';
import PhillipTagSelect from './components/data-tag-select/PhillipTagSelect.vue';
import PhillipLazyDisplay from './components/data-lazy-display/PhillipLazyDisplay.vue';

export default {
  install(Vue) {
    Vue.component('card', Card);
    Vue.component('icon', Icon);
    Vue.component('tabs', Tabs);
    Vue.component('tab-pane', TabPane);
    Vue.component('not-implemented-banner', NotImplementedBanner);

    Vue.component('phillip-form', PhillipForm);
    Vue.component('phillip-table', PhillipTable);
    Vue.component('phillip-select', PhillipSelect);
    Vue.component('phillip-tag-select', PhillipTagSelect);
    Vue.component('phillip-lazy-display', PhillipLazyDisplay);
  },
};
