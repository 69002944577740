<template>
  <form class="user-access-form" @submit.prevent="_submit">
    <!-- 1. Areas select -->
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">
        Zone
        <abbr class="text-danger" title="This field is required">*</abbr>
      </label>

      <div class="col-sm-9">
        <phillip-select select="area" v-model="area" />
      </div>
    </div>

    <!-- 2. Submit -->
    <div class="col-12 mt-3">
      <button :disabled="loading" type="submit" class="btn btn-primary btn-sm text-white">
        Save
      </button>
    </div>
  </form>
</template>

<script>
/**
 * Form used for specifiing the location of an object or access to a loaction.
 * The DataStorage passed as a parameter MUST implement the following methods:
 *
 *   getArea       Returns a list of area IDs
 *   setArea       Sets the area IDs
 *
 * The required methods will be generated automatically if a DataStorage has
 * the following connections:
 *   - area
 *
 * Some DataStorages can be passed here, while others cannot. Basically
 * anything which has a location can be passed here.
 */

import DataStorage from '../../model/abstract/data-storage';

export default {
  name: 'location-form',
  props: {
    storage: {
      type: DataStorage,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      area: null,
    };
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;

        const areas = await this.storage.getArea();
        this.area = areas[0] || null;
      } catch (e) {
        this.$modal.alert(e);
      } finally {
        this.loading = false;
      }
    },
    async _submit() {
      try {
        this.loading = true;

        await this.storage.setArea([this.area]);
      } catch (e) {
        this.$modal.alert(e);
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>
