// Layouts
import SimpleLayout from '../layouts/simple-layout/SimpleLayout.vue';
import DashboardLayout from '../layouts/dashboard-layout/DashboardLayout.vue';

// 404
import NotFound from '../views/404.vue';
const notFound = {
  path: '*',
  component: NotFound,
  meta: { contentClass: 'd-flex align-items-center justify-content-center' },
};

// Login
import Login from '../views/Login.vue';
const login = {
  path: '/login',
  component: Login,
  meta: { contentClass: 'd-flex align-items-center justify-content-center' },
};

// Password
import changePassword from './change-password-routes';
import resetPassword from './reset-password-routes';

// Home
import home from './home';

// Debug
import apiPlaygroud from './api-playground';

// Main pages
import areaRoutes from './area-routes';
import buildingRoutes from './building-routes';
import cameraRoutes from './camera-routes';
import chastiaDeviceRoutes from './chastia-device-routes';
import chastiaSensorRoutes from './chastia-sensor-routes';
import controlledEntryRoutes from './controlled-entry-routes';
import emailMessageRoutes from './email-message-routes';
import firebaseMessageRoutes from './firebase-message-routes';
import iosVoipMessageRoutes from './ios-voip-message-routes';
import licensePlateRoutes from './license-plate-routes';
import sessionRoutes from './session-routes';
import sipDoorRoutes from './sip-door-routes';
import userGroupRoutes from './user-group-routes';
import userRoutes from './user-routes';
import userSipAccountRoutes from './user-sip-account-routes';

// Actual routes
const routes = [
  { path: '/', redirect: '/home' }, // Initial redirection
  {
    path: '',
    component: DashboardLayout,
    children: [
      home,
      changePassword,
      apiPlaygroud,
      ...areaRoutes,
      ...buildingRoutes,
      ...cameraRoutes,
      ...chastiaDeviceRoutes,
      ...chastiaSensorRoutes,
      ...controlledEntryRoutes,
      ...emailMessageRoutes,
      ...firebaseMessageRoutes,
      ...iosVoipMessageRoutes,
      ...licensePlateRoutes,
      ...sessionRoutes,
      ...sipDoorRoutes,
      ...userGroupRoutes,
      ...userRoutes,
      ...userSipAccountRoutes,
    ],
  },
  {
    path: '',
    component: SimpleLayout,
    children: [login, resetPassword, notFound],
  },
];

export default routes;
