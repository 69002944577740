<template>
  <div id="app">
    <router-view />
    <form-modal v-if="session.jwt" />
    <license-plate-export-modal v-if="session.jwt" />
  </div>
</template>

<script>
import { connectorState } from './api/connector';
import session from './api/session.js';
import FormModal from './components/modals/FormModal';
import LicensePlateExportModal from './components/modals/LicensePlateExportModal';

export default {
  name: 'App',
  components: {
    FormModal,
    LicensePlateExportModal,
  },
  data() {
    return {
      connectorState,
      session,
    };
  },
  methods: {
    loginRoute() {
      if (this.$route.fullPath.startsWith('/reset-password/')) {
        return; // Do nothing
      }

      if (!this.session.jwt && this.$route.fullPath !== '/login') {
        this.$router.push('/login');
      }

      if (this.session.jwt && this.$route.fullPath === '/login') {
        this.$router.push('/');
      }
    },
  },
  watch: {
    'session.jwt': 'loginRoute',
    'connectorState.lastRequest': 'loginRoute',
  },
  created() {
    document.body.classList.add('sidebar-dark');
  },
};
</script>

<style lang="scss">
@import './assets/style.scss';

// todo: move this to scss files
$spacing-gap: 15px;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

body {
  margin: 0;
  min-height: 100vh;
}

#app {
  select {
    background-color: white;
    color: inherit;
  }

  textarea {
    height: 150px;
  }

  code {
    padding: 0;
  }

  color: rgb(30, 30, 40);
}

// prettier-ignore
@each $key, $value in $spacers {
  .ml-#{$key} { margin-left: $value !important; }
  .mr-#{$key} { margin-right: $value !important; }
  .mt-#{$key} { margin-top: $value !important; }
  .mb-#{$key} { margin-bottom: $value !important; }

  .pl-#{$key} { padding-left: $value !important; }
  .pr-#{$key} { padding-right: $value !important; }
  .pt-#{$key} { padding-top: $value !important; }
  .pb-#{$key} { padding-bottom: $value !important; }

  .ml--#{$key} { margin-left: -$value !important; }
  .mr--#{$key} { margin-right: -$value !important; }
  .mt--#{$key} { margin-top: -$value !important; }
  .mb--#{$key} { margin-bottom: -$value !important; }

  .pl--#{$key} { padding-left: -$value !important; }
  .pr--#{$key} { padding-right: -$value !important; }
  .pt--#{$key} { padding-top: -$value !important; }
  .pb--#{$key} { padding-bottom: -$value !important; }
}

.phillip-data-table {
  display: grid;
  grid-template-columns: 1fr 425px;
  grid-template-rows: 1fr;

  &.hide-filters {
    grid-template-columns: 1fr;
  }

  table.vgt-table {
    border: 0;

    font-size: 0.86rem;
    letter-spacing: -0.15px;
  }

  table.vgt-table td,
  table.vgt-table th {
    color: inherit;
    padding: 0.75em 0.3em;
  }

  table.vgt-table th span {
    white-space: nowrap;
  }

  .vgt-inner-wrap {
    box-shadow: none;
  }

  .vgt-wrap__footer {
    border: 0;
    padding-left: 6px;
    padding-right: 0;
    padding-bottom: 0;

    background: transparent;
  }

  .vgt-table thead th {
    padding: 0.5em 1.5em 0.5em 0.5em;

    background: #344258;
    border-color: #344258;
    color: white;

    font-weight: 500;
  }

  .add-new-btn {
    margin-right: 10px;
  }

  .row-actions {
    display: inline-block;

    color: rgb(160, 160, 160);

    font-size: 0.8em;

    opacity: 0;

    transition: opacity 80ms ease-in-out;
  }

  tr:hover td {
    background-color: rgb(250, 250, 250);
  }

  tr:hover .row-actions {
    opacity: 1;
  }

  .row-action {
    padding: 3px 5px;

    text-decoration: none;
  }

  a.row-action {
    cursor: pointer;
  }

  // Table filters
  .phillip-data-table-filters {
    align-self: flex-start;

    padding: 15px;
    margin-left: $spacing-gap;
    border-radius: 4px;

    background: #f8f9fb;

    .spacer {
      width: 100%;
      height: 1px;

      margin-top: 0.75rem;
      margin-bottom: 0.75rem;

      background: #eceff3;
    }
  }

  .table-filter-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e8ebf1;

    button {
      padding-top: 0;
      padding-bottom: 0;

      transform: translateX(1rem);
    }
  }

  .table-filter-row {
    margin-bottom: 0.75rem;
  }

  .table-filter-label {
    margin-bottom: 0.25rem;
  }

  .footer__row-count {
    display: none;
  }

  .footer__navigation {
    display: grid;
    grid-template-columns: 998fr 1fr 1fr;

    float: none !important;
  }

  .footer__navigation__page-info {
    margin-left: 0 !important;
    font-size: 0.8em;
    font-weight: 400;
    color: black !important;
  }

  .footer__navigation__page-btn {
    display: none !important;

    font-size: 0.95rem !important;
    font-weight: 600 !important;

    border: 1px solid #eee !important;
    border-radius: 3px;
    padding-top: 3px;
    padding-bottom: 3px;

    font-weight: 500 !important;

    cursor: pointer !important;
    transform: translateY(-0.1rem);

    // background: rgb(248, 248, 248) !important;
    background-color: #ececec !important;
    border-color: #ececec !important;
    color: black !important;

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

    &.disabled {
      cursor: not-allowed !important;
      opacity: 0.3 !important;
    }

    &:first-of-type {
      padding-right: 12px !important;
      margin-right: 5px !important;

      span.chevron::after {
        border-right-color: black;
      }
    }

    &:last-of-type {
      padding-left: 12px !important;

      span.chevron::after {
        border-left-color: black;
      }
    }

    span {
      font-size: 0.9rem !important;
    }

    span.chevron {
      margin-left: -4px;
      margin-right: -4px;
    }
  }

  &.paginated .footer__navigation__page-btn {
    display: block !important;
  }
}

.v-select {
  background: white;

  .vs__dropdown-toggle {
    padding: 2px 2px 6px 2px;
    border: 1px solid #e8ebf1;
  }
}

.card .card-body {
  padding: $spacing-gap !important;
}

.card.short {
  max-width: 1100px;
}

.card-nav {
  display: flex;
  align-items: stretch;

  a {
    @extend .btn, .btn-sm, .btn-light;

    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }

  a.router-link-active,
  a.router-link-active:hover,
  a.router-link-active:active {
    background-color: #727cf5;
    border-color: #727cf5;
    color: white;
  }

  .spacer {
    width: 10px;
  }
}

input::placeholder {
  opacity: 0.4;
}

.badge-danger {
  background-color: #dc3545;
}

.badge-pill {
  width: 2em;
  height: 2em;
  border-radius: 50% !important;
}

.main-wrapper .page-wrapper .page-content {
  padding: $spacing-gap !important;
  padding-bottom: 400px !important;
}

#creanet-text {
  padding: 10px;

  text-align: right;
  font-size: 0.85em;

  color: rgba(0, 0, 0, 0.75);

  a {
    color: #727cf5;
    font-weight: 500;
  }
}

#creanet-heart {
  display: inline-block;

  margin: 0 -1px;

  transform: scaleX(0.8);
}

.sidebar .sidebar-header .sidebar-brand {
  font-size: 23px !important;
}

.sidebar .sidebar-body .nav,
.sidebar .sidebar-header {
  padding-left: $spacing-gap !important;
  padding-right: $spacing-gap !important;
}

.sidebar .sidebar-body .nav .nav-item.active .nav-link::before {
  left: -$spacing-gap !important;
}
</style>
