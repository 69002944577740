const state = {
  /**
   * The access token used to access the endpoint used to change the password
   *
   * @type {string}
   */
  token: '',

  /**
   * The access code used to access the endpoint used to change the passwordň
   *
   * @type {string}
   */
  code: '',
};

const getters = {
  /**
   * Returns the access token
   *
   * @param {state} state
   */
  getToken: ({ token }) => token,

  /**
   * Returns the access code
   *
   * @param {state} state
   */
  getCode: ({ code }) => code,
};

const actions = {};

const mutations = {
  /**
   * Sets the access token
   *
   * @param {state} state
   * @param {string} payload
   */
  setToken: (state, payload) => (state.token = payload),

  /**
   * Resets the access token
   *
   * @param {state} state
   */
  resetToken: (state) => (state.token = ''),

  /**
   * Sets the access code
   *
   * @param {state} state
   * @param {string} payload
   */
  setCode: (state, payload) => (state.code = payload),

  /**
   * Resets the access code
   *
   * @param {state} state
   */
  resetCode: (state) => (state.code = ''),
};

const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
