<template>
  <div class="livestream-tab">
    <h5 class="card-title">Livestream</h5>
    <p class="text-muted mb-4">The livestream.</p>

    <p v-if="loading">Loading...</p>
    <p v-if="error">An error has ocurred.</p>

    <video
      style="width: 100%; height: auto"
      v-show="hlsSupported && !loading && !error"
      ref="video"
      class="videojs"
    ></video>
    <p v-show="!hlsSupported">Your browser does not support HLS.</p>
  </div>
</template>

<script>
import connector from '../../../api/connector';
import Hls from 'hls.js';

export default {
  name: 'camera-detail-livestream-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      hls: null,
      hlsSupported: null,
      loading: true,
      error: false,
      livestreamData: { hls: '', cookie: '' },
    };
  },
  methods: {
    attachMedia() {
      return new Promise((resolve) => {
        console.log('attach media', this.$refs.video);

        this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          console.log('media attached');
          resolve();
        });

        this.hls.attachMedia(this.$refs.video);
      });
    },
    async fetchData() {
      const { data } = await connector.get(`/cameras/${this.pk}/live`);

      console.log('data fetched');

      this.livestreamData.hls = data.hls;
      this.livestreamData.cookie = data.rawCookie;
    },
    async init() {
      await Promise.all([this.attachMedia(), this.fetchData()]);

      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log('manifest parsed');
        this.$refs.video.play();
      });

      this.hls.loadSource(this.livestreamData.hls);
    },
  },
  async mounted() {
    console.log(Date.now());
    this.hlsSupported = Hls.isSupported();
    console.log(Date.now());

    try {
      if (this.hlsSupported) {
        this.hls = new Hls({
          xhrSetup: function (xhr) {
            xhr.withCredentials = true; // do send cookies
          },
        });

        await this.init();
      }
    } catch (e) {
      this.error = true;
      // this.$modal.alert(e);
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    try {
      this.hls.destroy();
    } catch {
      // Do nothing
    }
  },
};
</script>
