<template>
  <div class="members-tab">
    <h5 class="card-title">Members</h5>
    <p class="text-muted mb-4">The members of the group.</p>
    <phillip-table
      table="user"
      hide-new-button
      hide-edit-button
      hide-filters
      delete-button-label="Remove from group"
      empty-text="The group is empty."
      :filters="{ 'groupId.eq': pk }"
      :on-delete="removeFromGroup"
    />
    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 450px"
        select="user"
        placeholder="Add user to group"
        v-model="user"
      />
      <button
        @click="addToGroup"
        :disabled="user === null"
        class="btn btn-sm btn-primary text-white ml-3"
      >
        Add to group
      </button>
    </div>
  </div>
</template>

<script>
import userFactory from '../../../model/user/user-factory';
import userGroupFactory from '../../../model/user-group/user-group-factory';

export default {
  name: 'user-detail-members-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    storage() {
      return userGroupFactory.pkMock(this.pk);
    },
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    addToGroup() {
      this.storage
        .addMember(this.user)
        .then(() => {
          this.user = null;
          userFactory.signalChange();
        })
        .catch((e) => this.$modal.alert(e));
    },
    removeFromGroup(userId) {
      this.$modal
        .confirm(`Delet user ${userId} from group?`)
        .then(() => {
          this.storage
            .deleteMember(userId)
            .then(() => userFactory.signalChange())
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // Do nothing
    },
  },
};
</script>
