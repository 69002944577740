<template>
  <div class="access-tab">
    <h5 class="card-title">Zones</h5>
    <p class="text-muted mb-4">The zones accessible by the group.</p>
    <phillip-table
      table="area"
      hide-new-button
      hide-edit-button
      delete-button-label="Deny access"
      hide-filters
      empty-text="The group can access no zones."
      :filters="{ 'userGroupAccess.eq': pk }"
      :on-delete="removeAccess"
    />
    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 450px"
        select="area"
        placeholder="Grant access to zone"
        v-model="area"
      />
      <button
        @click="allowAccess"
        :disabled="area === null"
        class="btn btn-sm btn-primary text-white ml-3"
      >
        Allow access
      </button>
    </div>
  </div>
</template>

<script>
import areaFactory from '../../../model/area/area-factory';
import userGroupFactory from '../../../model/user-group/user-group-factory';

export default {
  name: 'user-group-detail-access-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      area: null,
    };
  },
  methods: {
    allowAccess() {
      if (this.area !== null) {
        userGroupFactory
          .pkMock(this.pk)
          .addArea(this.area)
          .then(() => areaFactory.signalChange())
          .catch((e) => this.$modal.alert(e));
      }
    },
    removeAccess(areaId) {
      this.$modal
        .confirm(`Deny the group access from area ${areaId}?`)
        .then(() => {
          userGroupFactory
            .pkMock(this.pk)
            .deleteArea(areaId)
            .then(() => areaFactory.signalChange())
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // Do nothing
    },
  },
};
</script>
