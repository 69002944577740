<template>
  <sip-door-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters">
      <div class="spacer"></div>

      <!-- Filter by device type -->
      <div class="table-filter-row">
        <label for="sip-door-table-filter-device-type" class="table-filter-label"
          >Filter by device type</label
        >
        <vue-select
          id="sip-door-table-filter-device-type"
          :options="['VIDEO', 'AUDIO', 'CAMERA', 'UNIT']"
          placeholder="All device types"
          v-model="deviceType"
        />
      </div>

      <div class="spacer"></div>

      <!-- Filter by action method -->
      <div class="table-filter-row">
        <label for="sip-door-table-filter-action-method" class="table-filter-label"
          >Filter by action method</label
        >
        <vue-select
          id="sip-door-table-filter-action-method"
          :options="['RFC_4733', 'SIP_INFO', 'SIP_MESSAGE']"
          placeholder="All action methods"
          v-model="actionMethod"
        />
      </div>

      <div class="spacer"></div>

      <!-- Filter by area -->
      <div class="table-filter-row">
        <label for="sip-door-table-filter-area" class="table-filter-label">Filter by zone</label>
        <phillip-select
          select="area"
          id="sip-door-table-filter-area"
          placeholder="All zones"
          v-model="area"
        />
      </div>
    </template>
  </sip-door-table-base>
</template>

<script>
import SipDoorTableBase from './SipDoorTableBase';
import VueSelect from 'vue-select';

export default {
  name: 'sip-door-table',
  components: {
    SipDoorTableBase,
    VueSelect,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      if (this.deviceType) {
        filters['deviceType.eq'] = this.deviceType;
      }

      if (this.actionMethod) {
        filters['actionMethod.eq'] = this.actionMethod;
      }

      if (this.area) {
        filters['areaId.eq'] = this.area;
      }

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  methods: {
    clearFilters() {
      this.deviceType = null;
      this.actionMethod = null;
      this.area = null;
    },
  },
  data() {
    return {
      deviceType: null,
      actionMethod: null,
      area: null,
    };
  },
};
</script>
