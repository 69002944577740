<template>
  <div class="raw-tab">
    <h4 class="card-title">Chastia API</h4>
    <p class="text-muted mb-1">
      The Chastia API endpoints. <br />Click on an endpoint to open it in the browser.
    </p>
    <div v-if="chastiaSensor" class="mt-4">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 1px">Endpoint</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a
                :href="getEndpointUrl(`Sensors/Full/${chastiaSensor.chastiaId}`)"
                class="btn btn-primary btn-sm text-white"
                rel="external"
                target="_blank"
              >
                GET /Sensors/Full/{{ chastiaSensor.chastiaId }}
              </a>
            </td>
            <td>Fetch the detailed data about this sensor.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else class="mt-3">Loading ...</p>
  </div>
</template>

<script>
import { CHASTIA_API_BASE } from '../../../../environment';
import chastiaSensorFactory from '../../../../model/chastia-sensor/chastia-sensor-factory';

export default {
  name: 'chastia-sensor-detail-chastia-api-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      chastiaSensor: null,
    };
  },
  methods: {
    getEndpointUrl(endpoint) {
      return CHASTIA_API_BASE + endpoint;
    },
  },
  async created() {
    this.chastiaSensor = await chastiaSensorFactory.pk(this.pk);
  },
};
</script>
