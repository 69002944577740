<template>
  <div class="cameras-tab">
    <h5 class="card-title">Cameras</h5>
    <p class="text-muted mb-1">The cameras which the user can access.</p>
    <ul class="text-muted mb-4">
      <li>
        All cameras accessible by at least one group the user is a member of will be also accessible
        by the user.
      </li>
    </ul>
    <phillip-table
      table="camera"
      hide-new-button
      hide-filters
      hide-delete-button
      edit-button-label="Detail"
      empty-text="The user can access no cameras."
      :filters="{ 'userAccess.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'user-detail-cameras-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
