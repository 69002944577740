<template>
  <div>
    <phillip-table
      :table="factory.getModelName()"
      hide-filters
      hide-new-button
      hide-edit-button
      delete-button-label="Remove from zone"
      :empty-text="`No ${factory.getPlural()} in the zone.`"
      :filters="{ 'areaId.eq': areaId }"
      :on-delete="removeFromArea"
    />
    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 450px"
        :select="factory.getModelName()"
        :placeholder="`Add ${factory.getSingular()} to zone`"
        v-model="device"
      />
      <button
        @click="addToArea"
        :disabled="device === null"
        class="btn btn-sm btn-primary text-white ml-3"
      >
        Add {{ factory.getSingular() }}
      </button>
    </div>
  </div>
</template>

<script>
/**
 * Todo: comments
 */

import DataStorageFactory from '../../model/abstract/data-storage-factory';

export default {
  name: 'area-device-form',
  props: {
    factory: {
      type: DataStorageFactory,
      required: true,
    },
    areaId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      device: null,
    };
  },
  methods: {
    addToArea() {
      this.factory
        .pkMock(this.device)
        .setArea([this.areaId])
        .catch((e) => this.$modal.alert(e));
    },
    removeFromArea(deviceId) {
      this.$modal
        .confirm(`Remove ${this.factory.getSingular()} ${deviceId} from the zone?`)
        .then(() => {
          this.factory
            .pkMock(deviceId)
            .deleteArea(this.areaId)
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // do nothing
    },
  },
};
</script>
