<template>
  <div class="camera-tab">
    <h5 class="card-title">Snapshot</h5>
    <p class="text-muted mb-4">The snapshot of the camera provided by Molekula API.</p>
    <div v-if="camera">
      <img :src="imgsrc" alt="Error while loading image." />
    </div>
    <p v-else class="mt-3">Loading ...</p>
  </div>
</template>

<script>
import cameraFactory from '../../../model/camera/camera-factory';

export default {
  name: 'camera-detail-camera-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    imgsrc() {
      // todo: move this to API
      const url = new URL(
        `/video/snapshot.do?vid=${this.camera.molekulaDeviceId}`,
        this.camera.molekulaServer
      );

      url.username = 'admin';
      url.password = 'pass';

      return url.href;
    },
  },
  data() {
    return {
      camera: null,
    };
  },
  async created() {
    this.camera = await cameraFactory.pk(this.pk);
  },
};
</script>
