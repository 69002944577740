<template>
  <card
    class="page-session"
    title="All SIP accounts"
    description="The list of all SIP accounts owned by the users."
  >
    <phillip-table
      table="user-sip-account"
      hide-new-button
      hide-delete-button
      :on-edit="userDetail"
      :on-detail="userDetail"
    />
  </card>
</template>

<script>
export default {
  name: 'page-controlled-entries',
  methods: {
    userDetail(_, userSipAccount) {
      this.$router.push(`/user/detail/${userSipAccount.userId}/sip-account`);
    },
  },
};
</script>
