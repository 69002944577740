<template>
  <div class="cameras-tab">
    <h5 class="card-title">Cameras</h5>
    <p class="text-muted mb-1">The list of cameras accessible by the group.</p>
    <ul class="text-muted mb-4">
      <li>
        By default, a group can access a camera if they have access to the
        <abbr title="See the 'Zone' tab of a camera for more info">zone</abbr> of the camera.
      </li>
      <li>
        A group can be
        <abbr title="See the 'Blacklist' tab of a camera for more info">blacklisted</abbr> from
        accessing a camera which is in an accessible zone.
      </li>
      <li>
        A group can be
        <abbr title="See the 'Whitelist' of a camera tab for more info">whitelisted</abbr> to access
        a camera which is in an inaccessible zone.
      </li>
    </ul>
    <phillip-table
      table="camera"
      hide-new-button
      hide-filters
      hide-delete-button
      edit-button-label="Detail"
      empty-text="The group can access no cameras."
      :filters="{ 'userGroupAccess.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'user-group-detail-cameras-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
