import home from './home';
import EmailMessage from '../views/email-message/EmailMessage.vue';
import EmailMessageDetail from '../views/email-message/EmailMessageDetail.vue';

const emailMessage = {
  path: '/email-message',
  name: 'EmailMessages',
  component: EmailMessage,
  meta: { parent: home },
};

const emailMessageDetail = {
  path: '/email-message/detail/:pk',
  name: 'Firebase Message Detail',
  redirect: '/email-message/detail/:pk/email-message',
  component: EmailMessageDetail,
  meta: { parent: emailMessage, noLink: true },
};

// Tabs
import EmailMessageTab from '../views/email-message/tabs/EmailMessageTab.vue';

emailMessageDetail.children = [
  {
    path: '/email-message/detail/:pk/email-message',
    name: 'Firebase Message',
    component: EmailMessageTab,
    meta: { parent: emailMessageDetail },
  },
];

const emailMessageRoutes = [emailMessage, emailMessageDetail];

export default emailMessageRoutes;
