<template>
  <div class="blacklist-tab">
    <h5 class="card-title">Blacklist</h5>
    <p class="text-muted mb-4">
      The list of groups which are denied access to the controlled entry.
    </p>
    <blacklist-form
      :storage="controlledEntryFactory.pkMock(pk)"
      table-filter-name="controlledEntryBlacklist.eq"
    />
  </div>
</template>

<script>
import controlledEntryFactory from '../../../model/controlled-entry/controlled-entry-factory';
import BlacklistForm from '../../../components/forms/BlacklistForm.vue';

export default {
  name: 'controlled-entry-detail-blacklist-tab',
  components: {
    BlacklistForm,
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      controlledEntryFactory,
    };
  },
};
</script>
