<template>
  <div class="sensors-tab">
    <h5 class="card-title">Sensors</h5>
    <p class="text-muted mb-4">The sensors which the user can access.</p>

    <phillip-table
      hide-filters
      hide-new-button
      hide-edit-button
      delete-button-label="Blacklist sensor"
      empty-text="The user can access no Chastia sensors."
      table="chastia-sensor"
      :filters="{ 'userAccess.eq': pk, 'phillipImported.true': true }"
      :on-delete="(sensorId) => startOperation(OPERATION_BLACKLIST, sensorId)"
    />

    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 450px"
        select="chastia-sensor"
        placeholder="Whitelist sensor"
        v-model="sensor"
        :filters="{ 'phillipImported.true': true }"
      />
      <button
        @click="startOperation(OPERATION_WHITELIST)"
        :disabled="sensor === null"
        class="btn btn-sm btn-primary text-white ml-3"
      >
        Add sensor
      </button>
    </div>

    <modal
      :heading="modalHeading"
      :width="800"
      v-if="parent"
      @close="reset"
      @confirm="finishOperation"
    >
      <template slot="body">
        <p class="mb-3">
          All sensors which are children of Chastia device
          <phillip-lazy-display class="d-inline-block" display="chastia-device" :pk="parent" />
          will be {{ operation === OPERATION_WHITELIST ? 'whitelisted' : 'blacklisted' }}:
        </p>
        <phillip-table
          table="chastia-sensor"
          :filters="{ 'parent.eq': parent, 'phillipImported.true': true }"
          hide-filters
          hide-delete-button
          hide-edit-button
          hide-new-button
          show-all
          :exclude-columns="['parent', 'chastiaPeriodResult']"
        />
      </template>
    </modal>
  </div>
</template>

<script>
import chastiaSensorFactory from '../../../model/chastia-sensor/chastia-sensor-factory';
import Modal from '../../../components/modals/Modal.vue';
import chastiaDeviceFactory from '../../../model/chastia-device/chastia-device-factory';
import userGroupFactory from '../../../model/user-group/user-group-factory';

const OPERATION_WHITELIST = 0;
const OPERATION_BLACKLIST = 1;
const OPERATION_NAMES = ['Whitelist sensors', 'Blacklist sensors'];

export default {
  name: 'user-detail-sensors-tab',
  components: {
    Modal,
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    modalHeading() {
      return OPERATION_NAMES[this.operation];
    },
  },
  data() {
    return {
      sensor: null,
      parent: null,
      operation: null,
      OPERATION_WHITELIST,
      OPERATION_BLACKLIST,
    };
  },
  methods: {
    async startOperation(operation, sensorId) {
      this.operation = operation;

      try {
        const { parent } = await chastiaSensorFactory.pk(sensorId || this.sensor);
        this.parent = parent;
      } catch (error) {
        this.$modal.alert(error);
      }
    },
    async finishOperation() {
      try {
        const [group] = await userGroupFactory.fetch({ 'owner.eq': this.pk });
        const parent = await chastiaDeviceFactory.pk(this.parent);

        if (!group) {
          throw new Error(
            '[UNRECOVERABLE ERROR] user does not have a private group. Please, contact an administrator which can repair this error.'
          );
        }

        switch (this.operation) {
          case OPERATION_WHITELIST:
            await parent.deleteBlacklist(group.pk());
            await parent.addWhitelist(group.pk());
            break;

          case OPERATION_BLACKLIST:
            await parent.deleteWhitelist(group.pk());
            await parent.addBlacklist(group.pk());
            break;
        }
      } catch (error) {
        this.$modal.alert(error);
      } finally {
        chastiaSensorFactory.signalChange();
        this.reset();
      }
    },
    reset() {
      this.operation = null;
      this.sensor = null;
      this.parent = null;
    },
  },
};
</script>
