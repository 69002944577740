import home from './home';
import Building from '../views/building/Building.vue';
import NewBuilding from '../views/building/NewBuilding.vue';
import BuildingDetail from '../views/building/BuildingDetail.vue';

const building = {
  path: '/building',
  name: 'Buildings',
  component: Building,
  meta: { parent: home },
};

const newBuilding = {
  path: '/building/new',
  name: 'New Building',
  component: NewBuilding,
  meta: { parent: building },
};

const buildingDetail = {
  path: '/building/detail/:pk',
  name: 'Building Detail',
  redirect: '/building/detail/:pk/building',
  component: BuildingDetail,
  meta: { parent: building, noLink: true },
};

// Tabs
import BuildingTab from '../views/building/tabs/BuildingTab.vue';
import AreasTab from '../views/building/tabs/AreasTab.vue';
import UsersTab from '../views/building/tabs/UsersTab.vue';
import UserGroupsTab from '../views/building/tabs/UserGroupsTab.vue';
import SipDoorsTab from '../views/building/tabs/SipDoorsTab.vue';
import ControlledEntriesTab from '../views/building/tabs/ControlledEntriesTab.vue';
import CamerasTab from '../views/building/tabs/CamerasTab.vue';

buildingDetail.children = [
  {
    path: '/building/detail/:pk/building',
    name: 'Building',
    component: BuildingTab,
    meta: { parent: buildingDetail },
  },
  {
    path: '/building/detail/:pk/areas',
    name: 'Zones',
    component: AreasTab,
    meta: { parent: buildingDetail },
  },
  {
    path: '/building/detail/:pk/users',
    name: 'Users',
    component: UsersTab,
    meta: { parent: buildingDetail },
  },
  {
    path: '/building/detail/:pk/user-groups',
    name: 'Groups',
    component: UserGroupsTab,
    meta: { parent: buildingDetail },
  },
  {
    path: '/building/detail/:pk/sip-doors',
    name: 'SIP Doors',
    component: SipDoorsTab,
    meta: { parent: buildingDetail },
  },
  {
    path: '/building/detail/:pk/controlled-entries',
    name: 'Controlled Entries',
    component: ControlledEntriesTab,
    meta: { parent: buildingDetail },
  },
  {
    path: '/building/detail/:pk/cameras',
    name: 'Cameras',
    component: CamerasTab,
    meta: { parent: buildingDetail },
  },
];

const buildingRoutes = [building, newBuilding, buildingDetail];

export default buildingRoutes;
