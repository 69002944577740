<template>
  <license-plate-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters">
      <div class="spacer"></div>

      <!-- Filter by user -->
      <div v-if="areas.length === 0 && camera === null" class="table-filter-row">
        <label for="license-plate-table-filter-user" class="table-filter-label">
          Filter by users<br />
          <small class="text-muted">Multiple users can be selected.</small>
        </label>
        <phillip-select
          multiple
          select="user"
          id="license-plate-table-filter-user"
          placeholder="All users"
          v-model="users"
        />
      </div>

      <div class="spacer"></div>

      <!-- Filter by area -->
      <div v-if="users.length === 0 && camera === null" class="table-filter-row">
        <label for="license-plate-table-filter-area" class="table-filter-label">
          Filter by zone<br />
          <small class="text-muted">
            The license plates of all users who can access the specified zone. Multiple zones can be
            selected
          </small>
        </label>
        <phillip-select
          multiple
          select="area"
          id="license-plate-table-filter-area"
          placeholder="All zones"
          v-model="areas"
        />
      </div>

      <div class="spacer"></div>

      <!-- Filter by camera -->
      <div v-if="users.length === 0 && areas.length === 0" class="table-filter-row">
        <label for="license-plate-table-filter-camera" class="table-filter-label">
          Filter by camera<br />
          <small class="text-muted">
            The license plates of all users who can access the specified camera.
          </small>
        </label>
        <phillip-select
          select="camera"
          id="license-plate-table-filter-camera"
          placeholder="All cameras"
          v-model="camera"
        />
      </div>
    </template>
  </license-plate-table-base>
</template>

<script>
import LicensePlateTableBase from './LicensePlateTableBase';

export default {
  name: 'license-plate-table',
  components: {
    LicensePlateTableBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      if (this.users.length > 0) {
        filters['userId.in'] = this.users.join(',');
      }

      if (this.areas.length > 0) {
        filters['userAreaAccess.in'] = this.areas.join(',');
      }

      if (this.camera !== null) {
        filters['userCameraAccess.eq'] = this.camera;
      }

      Object.assign(filters, this.filters); // Props will always override filters

      this.$emit('filter', filters);

      return filters;
    },
  },
  methods: {
    clearFilters() {
      this.users = [];
      this.areas = [];
      this.camera = null;
    },
  },
  data() {
    return {
      users: [],
      areas: [],
      camera: null,
    };
  },
};
</script>
