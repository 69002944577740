<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <template v-for="part in routeParts">
        <template v-if="!part.last && !part.noLink">
          <li :key="`breadcrumb-part-${part.to}`" class="breadcrumb-item">
            <router-link :to="part.to">{{ part.title }}</router-link>
          </li>
        </template>
        <template v-else>
          <li
            :key="`breadcrumb-part-${part.to}`"
            class="breadcrumb-item active"
            aria-current="page"
          >
            {{ part.title }}
          </li>
        </template>
      </template>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'breadcrumbs',
  computed: {
    routeParts() {
      if (this.$route.matched.length == 0) {
        return [];
      }

      const parts = [];
      let page = this.$route.matched[this.$route.matched.length - 1];

      while (page != null) {
        const meta = page.meta || {};

        parts.push({
          to: page.path,
          title: page.name,
          noLink: Boolean(meta.noLink),
        });

        page = meta.parent;

        if (page == null || typeof page !== 'object' || !page.path || !page.name) {
          page = null;
        }
      }

      return parts.map((part, i) => ({ ...part, last: i == 0 })).reverse();
    },
  },
};
</script>
