import factory from '../../model/email-message/email-message-factory';

/**
 * Wait for 50ms for other Lazy Display components.
 */
const WAIT_FOR_OTHERS_DELAY = 50;

const state = {
  /**
   * The lazily collected primary keys
   *
   * @type {Set<number|string>}
   */
  pks: new Set(),

  /**
   * The fetched storages - map from primary key to full storage
   */
  storages: {},

  /**
   * The active timeout identifier
   */
  timeout: null,
};

const getters = {
  /**
   * @param {state} state
   */
  getStorages: ({ storages }) => storages,
};

const actions = {
  /**
   * Adds a new primary key to be fetched and resets the timer
   *
   * @param {{ state: state }}
   * @param {number|string} pk
   */
  addPk: ({ state, dispatch }, pk) => {
    state.pks.add(pk);

    if (state.timeout !== null) {
      clearTimeout(state.timeout);
    }

    state.timeout = setTimeout(() => dispatch('sendRequest'), WAIT_FOR_OTHERS_DELAY);
  },

  /**
   * Sends a request to fetch the collected primary keys
   *
   * @param {{ state: state }}
   */
  sendRequest: async ({ state }) => {
    if (state.pks.size > 0) {
      const pks = Array.from(state.pks.values());
      state.pks.clear();

      const storages = await factory.pkMultiple(pks);
      state.storages = storages.reduce(
        (table, storage) => ({ ...table, [storage.pk()]: storage }),
        {}
      );
    }
  },
};

const mutations = {};

const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
