<template>
  <card
    class="page-email-message"
    title="All email messages"
    description="The list of all email messages."
  >
    <phillip-table hide-new-button table="email-message" />
  </card>
</template>

<script>
export default {
  name: 'page-email-message',
};
</script>
