<template>
  <session-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters">
      <!-- Filter by user -->
      <div class="table-filter-row">
        <div class="spacer"></div>
        <label for="session-table-filter-user" class="table-filter-label">
          Filter by users<br />
          <small class="text-muted">Multiple users can be selected.</small>
        </label>
        <phillip-select
          multiple
          select="user"
          id="session-table-filter-user"
          placeholder="All users"
          v-model="users"
        />
      </div>
    </template>
  </session-table-base>
</template>

<script>
import SessionTableBase from './SessionTableBase';

export default {
  name: 'session-table',
  components: {
    SessionTableBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      if (this.users.length > 0) {
        filters['userId.in'] = this.users.join(',');
      }

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  methods: {
    clearFilters() {
      this.users = [];
    },
  },
  data() {
    return {
      users: [],
    };
  },
};
</script>
